<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Volunteers">
        <loading
          :active="isLoading"
          loader="bars"
          color="#7367f0"
          :is-full-page="fullPage"
        />
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          style-class="vgt-table striped"
          :sort-options="{
            enabled: false,
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoadingTable"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            skipDiacritics: true,
          }"
          :pagination-options="{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'view-volunteer', params: { id: props.row.id } }">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    <span>View</span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'edit-volunteer', params: { id: props.row.id } }">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else-if="props.column.field == 'cg'">
              {{ props.row.cg }}
            </span>
            <span v-else-if="props.column.field == 'attend_percentage'">
              <span v-if="props.row.attend_percentage === '-'">
                -
              </span>
              <span v-else>
                <span
                  v-for="item in props.row.attend_percentage.split(',')"
                  :key="item"
                >
                  <span
                    v-if="item === '-'"
                    style="margin-right: 5px;"
                  >
                    -
                  </span>
                  <b-badge
                    v-else
                    :class="cgPresenceResult(Number(item))"
                    style="margin-right: 5px;"
                  >
                    {{ Math.round(Number(item)) }}%
                  </b-badge>
                </span>
                <!-- <b-badge :class="cgPresenceResult(props.row.attend_percentage)">
                  {{ Math.round(props.row.attend_percentage) }}%
                </b-badge> -->
              </span>
            </span>
            <span v-else-if="props.column.field == 'status'">
              <b-badge
                v-if="props.row.status === 0 && props.row.end_year"
                variant="danger"
              >
                Non active
              </b-badge>
              <b-badge
                v-if="props.row.status === 0 && !props.row.end_year"
                variant="warning"
              >
                Need rereg
              </b-badge>
              <b-badge
                v-else-if="props.row.status === 1 && !props.row_end_year"
                variant="primary"
              >
                Need verification for reregistration
              </b-badge>
              <b-badge
                v-else-if="props.row.status === 2 && !props.row_end_year"
                variant="success"
              >
                Active
              </b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <div slot="table-actions-bottom">
            - The percentage of CG attendance is based on CG position as Simpatisan, Member, Sponsor or CGL<br>
            - The default percentage value for Coach, Team Leader & Pastoral Group are <strong>-</strong><br>
            - The percentage display order is based on the display order of CG name<br>
            - Minimum percentage of CG attendance allowed to serve is equal to or greater than <b-badge variant="warning">31.25%</b-badge>
          </div>
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard, BBadge, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BBadge,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      isLoadingTable: false,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Church',
          field: 'church',
        },
        {
          label: 'CG',
          field: 'cg',
        },
        {
          label: 'Ministry',
          field: 'ministry',
        },
        {
          label: 'Division',
          field: 'division',
        },
        {
          label: 'CG Attendance',
          field: 'attend_percentage',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          searchTerm: '',
        },
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 0,
        perPage: 10,
      },
      searchTerm: '',
      isRendered: false,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign(this.serverParams, Object.assign(newProps))
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPerPage * (params.currentPage - 1) })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch: _.debounce(function (params) {
      this.isLoading = true
      this.updateParams({ columnFilters: params })
      this.loadItems()
      return false
    }, 350),
    loadItems() {
      // Set data
      const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
      const ministries = userData[0].departments.map(elem => elem).join(',')
      const divisions = userData[0].divisions.map(elem => elem).join(',')
      const churches = userData[0].churches.map(elem => elem).join(',')

      // Get the data
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteers/${ministries}/${divisions}/${churches}?page=${this.serverParams.page}&perpage=${this.serverParams.perPage}&search=${this.serverParams.columnFilters.searchTerm}`)
        .then(response => {
          this.isLoading = false
          this.totalRecords = response.data.totalRecords
          this.rows = response.data.rows
      })
    },
    cgPresenceResult(result) {
      let badge = ''
      if (result >= 81.25) {
        badge = 'badge-success'
      } else if (result >= 56.25) {
        badge = 'badge-info'
      } else if (result >= 31.25) {
        badge = 'badge-warning'
      } else if (result < 31.25) {
        badge = 'badge-danger'
      }

      return badge
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
.vgt-wrap__actions-footer {
  border: none !important;
}
</style>